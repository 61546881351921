import Typography from 'typography';
import NoriegaTheme from 'typography-theme-noriega';

NoriegaTheme.overrideThemeStyles = () => ({
  a: {
    color: 'var(--textLink)',
  }
})
const typography = new Typography(NoriegaTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export const { scale, rhythm, options } = typography;
export default typography;